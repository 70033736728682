<template>
    <v-sheet>
        <v-card-title>
            Курсы валют
        </v-card-title>
        <v-card-actions>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" color="green" v-on:click="addItem">mdi-plus</v-icon>
                </template>
                <span>Добавить курс</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" color="orange" v-on:click="refreshRates">mdi-refresh</v-icon>
                </template>
                <span>Обновить данные из ЦБ РФ</span>
            </v-tooltip>
        </v-card-actions>
        <v-data-table :headers="headers" :items="items">
            <template v-slot:item.actions="{item}">
                <v-icon v-if="selectedItem !== item['@Currency']" color="orange" v-on:click="editItem(item)">mdi-pencil</v-icon>
                <v-icon v-else color="green" v-on:click="updateItem(item)">mdi-check</v-icon>
                <v-icon color="red" v-on:click="deleteItem(item)">mdi-delete</v-icon>
            </template>

            <template v-slot:item.Name="{item}">
                <p v-if="selectedItem !== item['@Currency']">{{item.Name}}</p>
                <v-text-field v-else v-model="item.Name"></v-text-field>
            </template>
            <template v-slot:item.Image="{item}">
                <template v-if="selectedItem !== item['@Currency']">
                    <v-avatar v-if="item.Image">
                    <v-img  alt="Изображение курса" :src="getStatic(item.Image)"></v-img>
                    </v-avatar>
                    <p v-else>Нет изображения</p>
                </template>
                <v-file-input hide-input v-else v-model="item.Image"></v-file-input>
            </template>

            <template v-slot:item.LastUpdateDate="{item}">
                <p v-if="!item.LastUpdateDate" style="color: darkred">Не обновлялось</p>
                <p v-else>{{item.LastUpdateDate}}</p>
            </template>

            <template v-slot:item.Code="{item}">
                <p v-if="selectedItem !== item['@Currency']">
                    {{item.Code}}
                </p>
                <v-text-field v-else v-model="item.Code">
                </v-text-field>
            </template>
            <template v-slot:item.Aliases="{item}">
                <template v-if="item.Aliases">
                    <div class="d-flex flex-row align-center" v-for="alias of item.Aliases" :key="`currency-${item['@Currency']}-alias-${alias}`">
                        <p class="my-0">{{alias}}</p>
                        <v-icon
                                v-if="selectedItem === item['@Currency']"
                                small
                                color="red"
                                v-on:click="item.Aliases.splice(item.Aliases.indexOf(alias), 1)"
                        >mdi-close</v-icon>
                    </div>
                </template>
                <template v-if="selectedItem === item['@Currency']">
                    <div class="d-flex flex-row align-center" style="max-width: 200px; overflow-x: auto">
                    <v-text-field @keyup="uppercase()" style="width: 100px;" v-model="newAlias"></v-text-field>
                    <v-btn icon v-on:click="addAlias(item)"><v-icon color="green">mdi-plus</v-icon></v-btn>
                    </div>
                </template>
            </template>
        </v-data-table>

        <p>Полные названия и коды валют можно посмотреть <a target="_blank" href="https://www.cbr.ru/scripts/XML_val.asp?d=0">на официальной странице ЦБ РФ</a></p>
    </v-sheet>
</template>

<script>
    import axios from 'axios';
    import {getStatic, getURL} from "../../settings";

    export default {
        name: "Currency",
        mounted() {
            this.loadCurrencies();
        },
        methods: {
            uppercase() {
                if(!this.newAlias)
                    return;
                this.newAlias = this.newAlias.toUpperCase();
            },
            getStatic,
            addAlias(item) {
                if(!item.Aliases) {
                    item.Aliases = [];
                }
                item.Aliases.push(this.newAlias);
                this.newAlias = null;
            },
            addItem() {
                const newItem = {...this.emptyItem}
                this.items.push(newItem);
                this.selectedItem = -1;
            },
            refreshRates() {
                axios.get(getURL('admin/currency/update_rates')).then(() => {
                    this.loadCurrencies();
                })
            },
            editItem(item) {
                this.selectedItem = item['@Currency'];
            },
            updateItem(item) {
                item['action'] = item['@Currency'] < 0 ? 'create' : 'update';
                axios.post(getURL('admin/currency'), item).then(() => {
                    this.loadCurrencies();
                    this.selectedItem = null;
                })
            },
            deleteItem(item) {
                if(item['@Currency'] < 0) {
                    this.loadCurrencies();
                    return;
                }

                item['action'] = 'delete';
                axios.post(getURL(`admin/currency`), item).then(() => {
                    this.loadCurrencies();
                })
            },
            loadCurrencies() {
                axios.get(getURL('admin/currency/load_all')).then(res => {
                    this.items = res.data;
                })
            }
        },
        data: () => {
            return {
                newAlias: null,
                emptyItem: {
                    '@Currency': -1,
                    'Name': null,
                    'Image': null,
                    'Price': null,
                    'Aliases': null,
                    'LastUpdateDate': null
                },
                selectedItem: null,
                headers: [
                    {
                        value: 'actions',
                        text: 'Действия'
                    },
                    {
                        value: 'Name',
                        text: 'Название'
                    },
                    {
                        value: 'Image',
                        text: 'Изображение'
                    },
                    {
                        value: 'Price',
                        text: 'Курс'
                    },
                    {
                        value: 'Aliases',
                        text: 'Обозначения'
                    },
                    {
                        value: 'Code',
                        text: 'Код ЦБ'
                    },
                    {
                        value: 'LastUpdateDate',
                        text: 'Обновлено'
                    }
                ],
                items: []
            }
        }
    }
</script>

<style scoped>

</style>
